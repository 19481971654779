import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Div from "@components/Div";
import FullSizeDialog from "@components/FullSizeDialog";
import styled from "styled-components";
import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import ProgressSpinner from "@components/ProgressSpinner";
import { useToast } from "@hooks/useToast";
import { SurveyForm } from "@components/Survey";
import { useIntl } from "react-intl";
import updateEmailTemplateService from "@app/services/emailTemplate/updateEmailTemplateService";
import emailTemplateConfig from "./emailTemplateConfig.json";
import { emailTeamplateServiceById } from "@app/services/emailTemplate/emailTemplatesService";

const StyledCreateBilling = styled.div`
  width: 1110px;
`;
const EditEmailTemplatesDialog = ({ editEmailTemplateId, handleClose = () => {} }) => {
  const { messages } = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const surveyRef = useRef(null);
  const { showErrorToast, showSuccessToast } = useToast();
  const [emailTemplateData, setEmailTemplateData] = useState(null); 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await emailTeamplateServiceById(editEmailTemplateId);
        const EmailTemplateData = data.data[0]; 
        setEmailTemplateData(EmailTemplateData);
      } catch (error) {
        setEmailTemplateData(null); 
      } 
    };
      fetchData();
  }, [editEmailTemplateId]);
  const handleUpdateCase = useCallback(
    async (data) => {
      try {
        setIsLoading(true);
        await updateEmailTemplateService(editEmailTemplateId, data);
        showSuccessToast(messages.updated);
        handleClose();
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    },
    [editEmailTemplateId, messages, showErrorToast, handleClose, showSuccessToast]
  );
  const handleCompleteSurvey = useCallback(
    (survey) => {
      handleUpdateCase(survey);
    },
    [handleUpdateCase]
  );
  const handleLoadSurveyRef = (ref) => {
    surveyRef.current = ref;
  };
  const handleSave = () => {
    const { data } = surveyRef?.current ?? {};
    handleUpdateCase(data);
  };
  return (
    <Div>
      {isLoading && <ProgressSpinner />}
      <FullSizeDialog
        title={messages.label_edit_view_email_template}
        onClose={handleClose}
      >
        <StyledCreateBilling>
          <Div bgColor="var(--white)">
            <SurveyForm
              data={emailTemplateData} 
              formConfig={emailTemplateConfig}
              onComplete={handleCompleteSurvey}
              onRefLoaded={handleLoadSurveyRef}
            />
          </Div>
          
          {!isLoading && (
            <Div
              display="flex"
              flexDirection={["column", "column", "row", "row"]}
              gridGap={3}
              
            >
              <PrimaryButton
                minWidth="150px"
                label={messages.label_save}
                onClick={handleSave}
              />
              <PrimaryButtonOutlined
                backgroundColor="transparent !important"
                minWidth="150px"
                label={messages.label_back}
                onClick={handleClose}
              />
            </Div>
          )}
        </StyledCreateBilling>
      </FullSizeDialog>
    </Div>
  );
};
EditEmailTemplatesDialog.propTypes = {
  handleClose: PropTypes.func,
  editEmailTemplateId: PropTypes.string.isRequired, 
};
export default EditEmailTemplatesDialog;