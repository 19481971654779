import React, { useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import Div from "@components/Div";
import { Text } from "@components/Text";
import { PrimaryButtonIcon, PrimaryButtonOutlined } from "@components/Button";
import Dialog from "@components/Dialog";
import { H3 } from "@components/Heading";
import Label from "@components/Label";
import Checkbox from "@components/Checkbox";

const ConfirmationPopup = props => {
  const { messages } = useIntl();
  const {
    title,
    description,
    acceptButtonLabel = messages.yes_label,
    cancelButtonLabel = messages.label_cancel,
    onCancelClick,
    onAcceptClick,
    showConfirmationCheckbox = true,
  } = props;

  const [isChecked, setIsChecked] = useState(!showConfirmationCheckbox);

  const handleCheckboxChange = e => {
    setIsChecked(e.target.checked);
  };

  return (
    <Dialog
      header={
        <Div textAlign="center" textTransform="none">
          {/* Title */}
          <H3 textTransform="none">{title}</H3>
        </Div>
      }
      visible={true}
      draggable={false}
      textAlign="center"
      onHide={onCancelClick}
      m={[3, 3, "auto", "auto"]}
      width={[1, 1, 500, 500]}
    >
      <Div
        display="flex"
        alignItems="center"
        flexDirection="column"
        textAlign="center"
        mt={3}
      >
        <Div textAlign="center" display="grid">
          <Text>{description}</Text>
        </Div>

        {/* Checkbox */}
        {showConfirmationCheckbox === true && (
          <Div
            mt={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Label
              mt={10}
              fontSize="var(--fs-text-m) !important"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Checkbox
                onChange={handleCheckboxChange}
                checked={isChecked}
                mr={2}
              />
              {messages.label_delete_checkbox}
            </Label>
          </Div>
        )}


        <Div
          mt={4}
          px={[0, 4]}
          width={1}
          display="flex"
          flexDirection={["column-reverse", "row"]}
          justifyContent="space-between"
        >
          <PrimaryButtonOutlined
            rounded
            semibold
            label={cancelButtonLabel}
            onClick={onCancelClick}
            mt={[3, 0]}
            width={[1, "47%"]}
          />
          {onAcceptClick && (
            <PrimaryButtonIcon
              rounded
              semibold
              width={[1, "47%"]}
              onClick={onAcceptClick}
              label={acceptButtonLabel}
              disabled={!isChecked} // Disable button if checkbox is not checked
            />
          )}
        </Div>
      </Div>
    </Dialog>
  );
};
ConfirmationPopup.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  acceptButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  onCancelClick: PropTypes.func.isRequired,
  onAcceptClick: PropTypes.func,
  showConfirmationCheckbox: PropTypes.bool, // Added here for validation
};

export default ConfirmationPopup;
