import Axios from "@app/api/axios";
import { EMAIL_TEMPLATES } from "@utils/constant";

const emailTeamplatesService = () =>
  Axios.get(`${EMAIL_TEMPLATES}`);
export default emailTeamplatesService;

export const emailTeamplateServiceById = (id) => 
  Axios.get(`${EMAIL_TEMPLATES}?filter[id]=${id}`); 

